import React from 'react';

import RVLogo from '~/images/rv.svg';
import FooterBackground from '~/images/footer.png';

const Footer = () => (
  <footer className="my-12">
    <div className="h-40">
      <img
        src={FooterBackground}
        alt="Series of shapes"
        className="h-full w-full object-cover"
      />
    </div>
    <div className="mt-8 mx-8 md:mx-12 h-20 flex flex-row items-center">
      <div className="h-full flex-shrink-0">
        <img src={RVLogo} alt="RV" className="h-full object-contain" />
      </div>
      <div className="ml-8 text-xs flex-1">
        <div>
          Brought to you by the creators of{' '}
          <a
            href="https://parsnip.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            Parsnip
          </a>
        </div>
        <div className="mt-2">
          Designed by{' '}
          <a
            href="https://emilyhines.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            Emily Hines
          </a>
        </div>
      </div>
    </div>
  </footer>
);
export default Footer;
