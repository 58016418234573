import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Container from '~/components/container';
import '~/styles/layout.css';

const Layout = ({ children }) => (
  <>
    <div className="flex flex-col h-full">
      <Header />
      <main className="flex-1 mx-auto w-full">
        <div className="max-w-sm sm:max-w-5xl w-full mx-auto">{children}</div>
      </main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
